.contact {
  background-color: $dark-green;
  .content {
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    .contact-info {
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      color: #fff;
      ul {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        li {
          width: 50%;
          list-style-type: none;
          display: flex;
          padding: 20px;
          a, span {
            display: flex;
            text-decoration: none;
            img {
              width: 22px;
              height: 22px;
              margin-right: 20px;
            }
            span {
              margin-right: 20px;
              color: #fff;
            }
          }
          &:nth-child(odd) {
            border-right: 1px solid #fff;
            justify-content: flex-end;
            a, span {
              flex-flow: row-reverse;
              span {
                margin-right: 20px;
              }
            }
          }
          &:hover {
            opacity: .7;
          }
        }
        .ms-teams {
          span {
            align-items: center;
            text-align: center;
            img {
              width: 30px;
              height: 30px;
            }
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .onboarding-forms {
      padding: 50px 50px;

      .wrapper {
        width: 100%;
        max-width: 800px;
        
        h3 {
          color: #fff;
          text-align: center;
          margin-bottom: 40px;
        }

        .form-buttons {
          display: flex;
          flex-flow: wrap;

          a {
            background-color: $light-green;
            padding: 5px 20px;
            font-size: 18px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 400;
            letter-spacing: 1px;
            color: #fff;
            border: 0;
            border-radius: 4px;
            margin-top: 20px;
            text-decoration: none;
            margin: 0 10px;

            img {
              max-width: 15px;
              margin-left: 5px;
            }

            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  }

  @media  screen and (max-width: 600px) {
    .content {
      .contact-info {
        ul {
          li {
            width: 100%;
            border: 0 !important;
            margin-bottom: 20px;
            a, span {
              width: 100%;
              flex-flow: column !important;
              justify-content: flex-start;
              text-align: center;
              margin-right: 0px !important;

              img {
                width: 100%;
                margin: 0px !important;
              }
              img {
                margin-bottom: 10px !important;
              }
            }
          }
          .ms-teams {
            span {
              display: flex;
              flex-flow: column !important;
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media  screen and (max-width: 500px) {
    .content {
      .onboarding-forms {
        .wrapper {
          .form-buttons {
            justify-content: center;
            flex-direction: column;

            a {
              margin: 0 0 10px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
