*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $dark-gray;
  letter-spacing: .125px;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
}

.App {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
}
.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 75px 20px;
  .section-header {
    text-align: center;
    width: 100%;
    color: #fff;
    margin-bottom: 30px;
  }
  .content {
    width:100%;
    display: flex;
    flex-flow: wrap;
    max-width: 1000px;
  }
}

.sub-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: $light-gray;
  letter-spacing: 3px;
  line-height: 10px;
  color: #fff;
  margin-bottom: 5px;
}

.section-title {
  font-family: 'PT Serif', serif;
  font-weight: 700;
  font-size: 32px;
  color: $light-gray;
  color: #fff;
  line-height: 36px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

.App-header .logo {
  margin-bottom: 150px;
}

.App-header h1 {
  font-family: 'Roboto Slab', serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.App-header .contact-links {
  display: flex;
  justify-content: center;
}

.App-header .contact-links img {
  width: 30px;
  margin: 0px 15px;
  transition: all .3s;
}

.App-header .contact-links img:hover {
  opacity: .5;
}

.App-header .divider {
  width: 35%;
  height: 1px;
  background-color: #fff;
  margin: 50px 0px;
}

.App-header h2 {
  font-size: 24px;
  font-weight: 300;
}

@media screen and (max-width: 600px) {
  .App-header h1 {
    font-size: 1.125rem;
  }
}
