.disclaimers {
  background-color: $light-gray;
  .content {
    // max-width: 600px;
    .sub-title,
    p, a, strong {
      color: $lightest-gray;
    }
  }
}
