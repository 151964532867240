#CustodyClearing {
  background-color: rgba($light-gray, .9);
  .content {
    display: flex;
    align-items: center;

    .section-header, .info {
      width: 50%;    
      flex-flow: wrap;
    }

    .section-header {
      h2 {
        padding: 0 20px;
        color: $lightest-gray;
      }
    }

    .info {
      p {
        color: $lightest-gray;
        margin-bottom: 30px;
        a {
          display: inline-block;
        }
      } 
      a {
        color: $lightest-gray;
        transition: all .3s;
        &:hover {
          color: $light-green
        }
      }
      a.button {
        display: inline-block;
        background-color:$dark-green;
        padding: 5px 20px;
        font-size: 14px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        letter-spacing: 1px;
        color: #fff;
        border: 0;
        border-radius: 4px;
        margin-top: 20px;
        text-decoration: none;
        transition: all .2s;
        &:hover {
          opacity: .7;
          cursor: pointer;
          background-color: darken($dark-green, 2%);
        }
        &:first-of-type { 
          margin-right: 10px;
        }
      }
    }
  }
  @media  screen and (max-width: 900px) {
    .content {
      flex-flow:column;

      .section-header, .info {
        width: 100%;
      }

      .info {
        a {
          display: inline-block;
        }
        a.button {
          text-align: center;
          display: block;
          width: fit-content;
          margin: 10px auto 10px !important;
        }
      }
    }
  }
}
