.hero {
  background-image: url('../../assets/images/Miami-Background-LG.png');
  background-size:cover;
  background-position: center;
  min-height: 75vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .content {
    width: 50%;
    text-align: center;
  }
  
  @media  screen and (max-width: 1100px) {
    min-height: 50vh;
  }
  @media  screen and (max-width: 900px) {
    background-image: url('../../assets/images/Miami-Background.png')
  }

  @media  screen and (max-width: 440px) {
    min-height: 70vh;
    .content {
      width: 100%;
    }
  }
}
