*,
*:before,
*:after {
  box-sizing: border-box;
}

@import 'variables.scss';
@import 'mixins.scss';
@import '../components/App/App';
@import '../components/Nav/Nav';
@import '../components/Hero/Hero';
@import '../components/About/About';
@import '../components/Services/Services';
@import '../components/Contact/Contact';
@import '../components/CustodyClearing/CustodyClearing';
@import '../components/Disclaimers/Disclaimers';
@import '../components/Footer/Footer';
