.header {
  position: sticky;
  top:0px;
  background-color: #fff;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.29); 
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.29);
  height: 80px;
  .logo {
    width: 180px;
    margin-bottom: -3px;
    cursor: pointer;  
    img {
      width: 100%;
    }
  }
  nav {
    a {
      margin-left: 20px;
      background-color: transparent;
      border: 0;
      color: $dark-gray;
      text-decoration: none;
      font-weight: 500;
      font-size: 18px;
      transition: all .3s;
      &:hover {
        opacity: .7;
        cursor: pointer;
      }
    }
  }
  @media  screen and (max-width: 440px) {
    padding: 20px;
    .logo {
      width: 120px;
    }
    nav {
      a {
        font-size: 15px;
        margin-left: 14px;
      }
    }
  }
}
