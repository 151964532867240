.services {
  background-color: $light-green;
  display: flex;
  flex-flow: wrap;
  .content {
    display: flex;
    justify-content: center;
    
    .list {
      max-width: 800px;
      width: 100%;
      display: flex;
      flex-flow: wrap;
      ul {
        flex: 1;
        margin-left: 20px;
        li {
          color: #fff;
          font-size: 18px;
          margin-bottom: 5px;
        }
      }
    }
  }
  @media  screen and (max-width: 600px) {
    .content {
      text-align: left;
      .section-header {
        text-align: left;
      }
      .list {
        flex-flow: column;
        ul {
          width: 100%;
          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
